import utility from './utility';

export default (): void => {
    const Modal = (() => {
        let idCountNum = 0;

        // モーダル表示切り替え時にpadding操作する対象要素
        const adjustPadding = [
            document.body,
            document.querySelector('.header') as HTMLElement,
            document.querySelector('.gdprBanner') as HTMLElement
        ];

        // windowイベントでモーダルの開閉を判定する為の配列
        const modalInstance: any[] = [];

        // ページ内のモーダル開閉管理用フラグ
        let modalOpenFlag = false;

        // モーダルが開いてる際に画面外からフォーカス移動して来た際
        window.addEventListener('focus', () => {
            if (!modalOpenFlag) {
                return;
            }

            for (let i = 0; i < modalInstance.length; i++) {
                const instance = modalInstance[i];

                // ルート要素にis-openが付与されていて、そのパネル内にフォーカスがアクティブな要素が無い時に、1番目のフォーカス要素をフォーカスする。
                if (
                    instance.root.classList.contains(utility.className.open) &&
                    !instance.panel.contains(document.activeElement)
                ) {
                    instance.panelFocusElems[0].focus();
                    break;
                }
            }
        });

        return class {
            private root: HTMLElement;
            private openBtn: HTMLAnchorElement | HTMLButtonElement;
            private closeBtn: HTMLAnchorElement | HTMLButtonElement;
            private panel: HTMLElement;
            private panelInner: HTMLElement;
            private panelContents: HTMLElement;
            private animationFlag: boolean;
            private body: HTMLElement;
            private panelFocusElems: NodeListOf<HTMLAnchorElement | HTMLButtonElement>;

            constructor(root: HTMLElement) {
                this.root = root;
                this.openBtn = root.querySelector('.modal__openBtn') as HTMLAnchorElement | HTMLButtonElement;
                this.closeBtn = root.querySelector('.modal__closeBtn') as HTMLAnchorElement | HTMLButtonElement;
                this.panel = root.querySelector('.modal__panel') as HTMLElement;
                this.panelInner = root.querySelector('.modal__panelInner') as HTMLElement;
                this.panelContents = root.querySelector('.modal__panelContents') as HTMLElement;
                this.animationFlag = false;
                this.body = document.body;
                this.panelFocusElems = this.panelInner.querySelectorAll('a, button') as NodeListOf<HTMLAnchorElement | HTMLButtonElement>;

                this.panel.id = `modal-${idCountNum}`;
                this.panelInner.setAttribute('aria-modal', 'true');
                this.panelInner.setAttribute('role', 'dialog');
                this.openBtn.setAttribute('aria-expanded', 'false');
                this.openBtn.setAttribute('aria-controls', this.panel.id);
                this.openBtn.setAttribute('aria-haspopup', 'dialog');

                // 画像表示モーダルの場合パネル内に画像を複製する。
                if (this.root.classList.contains('is-imgPanel')) {
                    const img = this.openBtn.querySelector('img');

                    if (img) {
                        const panelImg = img.cloneNode();

                        if (this.panelContents) {
                            this.panelContents.appendChild(panelImg);
                        } else {
                            this.panelInner.appendChild(panelImg);
                        }
                    }
                }

                // モーダルパネル表示ボタンで開く
                this.openBtn.addEventListener('click', this.openBtnClickHandler);

                // モーダルパネル内の閉じるボタンでパネルを閉じる
                this.closeBtn.addEventListener('click', this.closeBtnClickHandler);

                // オーバーレイ部分でモーダルパネルを閉じる
                this.panel.addEventListener('click', this.panelClickHandler);

                /*
                 * モーダルパネル内のフォーカス要素数によってイベント付与切り替え
                 *
                 * - 閉じるボタン以外にフォーカス要素がパネル内にある時
                 *  → 最初と最後（＝閉じるボタン）のフォーカス要素にTAB制御のイベント付与
                 *
                 * - パネル内のフォーカス要素が閉じるボタンだけの時
                 *  → フォーカス要素（＝閉じるボタン）にTAB制御のイベント付与
                 */
                if (this.panelFocusElems.length > 1) {
                    this.panelFocusElems[0].addEventListener('keydown', this.firstFocusElemKeydownHandler);
                    this.panelFocusElems[this.panelFocusElems.length - 1].addEventListener('keydown', this.lastFocusElemKeydownHandler);
                } else if (this.panelFocusElems.length === 1) {
                    this.panelFocusElems[0].addEventListener('keydown', this.closeBtnKeydownHandler);
                }

                // id用のカウントを更新
                idCountNum++;

                // インスタンスへの参照を配列に格納する
                modalInstance.push(this);
            }

            /**
             * openBtnClickHandler
             *
             * パネル表示用ボタンclickハンドラー
             *
             */
            private openBtnClickHandler = (): void => {
                if (this.animationFlag) {
                    return;
                }

                this.animationFlag = true;

                this.pnaelOpen();

                this.animationFlag = false;
            }

            /**
             * closeBtnClickHandler
             *
             * パネル内の閉じるボタンclickハンドラー
             *
             */
            private closeBtnClickHandler = (): void => {
                if (this.animationFlag) {
                    return;
                }

                this.animationFlag = true;

                this.pnaelClose();

                this.animationFlag = false;
                this.openBtn.focus();
            }

            /**
             * panelClickHandler
             *
             * パネルオーバーレイ部分clickハンドラー
             *
             */
            private panelClickHandler = (e: MouseEvent): void => {
                if (this.animationFlag) {
                    return;
                }

                if (e.target === e.currentTarget) {
                    this.animationFlag = true;

                    this.pnaelClose();

                    this.animationFlag = false;
                    this.openBtn.focus();
                }
            }

            /**
             * firstFocusElemKeydownHandler
             *
             * パネル内に閉じるボタン以外にフォーカス要素がある時の1番目のフォーカス要素Keydownハンドラー
             *
             */
            private firstFocusElemKeydownHandler = (e: KeyboardEventInit & Event): void => {
                if (
                    e.shiftKey &&
                    e.key === 'Tab'
                ) {
                    e.preventDefault();

                    this.panelFocusElems[this.panelFocusElems.length - 1].focus();
                }
            }

            /**
             * lastFocusElemKeydownHandler
             *
             * パネル内に閉じるボタン以外にフォーカス要素がある時の最後のフォーカス要素（＝閉じるボタン）Keydownハンドラー
             *
             */
            private lastFocusElemKeydownHandler = (e: KeyboardEventInit & Event): void => {
                if (
                    !e.shiftKey &&
                    e.key === 'Tab'
                ) {
                    e.preventDefault();

                    this.panelFocusElems[0].focus();
                }
            }

            /**
             * closeBtnKeydownHandler
             *
             * パネル内にフォーカス要素が閉じるボタンしかない時の閉じるボタンKeydownハンドラー
             *
             */
            private closeBtnKeydownHandler = (e: KeyboardEventInit & Event): void => {
                if (e.key === 'Tab') {
                    e.preventDefault();
                }
            }

            /**
             * pnaelOpen
             *
             * パネルを開く処理
             *
             */
            private pnaelOpen = (): void => {
                this.openBtn.setAttribute('aria-expanded', 'true');
                this.root.classList.remove(utility.className.close);
                this.root.classList.add(utility.className.open);
                this.changeBodyFixed();
                modalOpenFlag = true;

                setTimeout(() => {
                    this.setPanelFocus();
                }, 100);
            }

            /**
             * pnaelClose
             *
             * パネルを閉じる処理
             *
             */
            private pnaelClose = (): void => {
                this.openBtn.setAttribute('aria-expanded', 'false');
                this.root.classList.remove(utility.className.open);
                this.root.classList.add(utility.className.close);
                modalOpenFlag = false;
                this.changeBodyFixed();
            }

            /**
             * setPanelFocus
             *
             * パネルを開く時にパネルにフォーカス移動する処理
             *
             */
            private setPanelFocus = (): void => {
                // this.panelInner.tabIndex = 0;

                // this.panelInner.focus();

                // this.panelInner.removeAttribute('tabindex');
                this.panelFocusElems[0].focus();
            }

            /**
             * changeBodyFixed
             *
             * パネル開閉時のbodyのclass付け替え処理
             *
             */
            private changeBodyFixed = (): void => {
                if (this.body.classList.contains(utility.className.fixed)) {
                    adjustPadding.forEach(item => {
                        item.style.paddingRight = '';
                    });
                    this.body.classList.remove(utility.className.fixed);
                } else {
                    const beforeBodyWidth = this.body.clientWidth;

                    this.body.classList.add(utility.className.fixed);

                    const afterBodyWidth = this.body.clientWidth;

                    const diffWidth = afterBodyWidth - beforeBodyWidth;

                    if (diffWidth > 0) {
                        adjustPadding.forEach(item => {
                            item.style.paddingRight = `${diffWidth}px`;
                        });
                    }
                }
            }
        };
    })();

    const roots = document.querySelectorAll('.js-modal');
    const rootsLength = roots.length;

    if (rootsLength > 0) {
        for (let i = 0; i < rootsLength; i++) {
            new Modal(roots[i] as HTMLElement); // eslint-disable-line
        }
    }
};
