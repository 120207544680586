

















































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import I18n from '@/i18n/index';
import controlCarousel from '@/_mitsue-modules/src/ts/module/controlCarousel';
import currentLink from '@/_mitsue-modules/src/ts/module/currentLink';
import modal from '@/_mitsue-modules/src/ts/module/modal';
import utility from '@/_mitsue-modules/src/ts/module/utility';

@Component({
  components: {},
})
export default class Index extends Vue {
  private i18n = I18n;

  @Watch('$route')
  async onRouteChanged(): Promise<void> {
    if (this.$i18n.locale === 'en') {
      this.$router.push(`/en/`);
    }
  }

  mounted(): void {
    modal();
    currentLink();
    controlCarousel('.carousel__contents', '.carousel__nextBtn', '.carousel__prevBtn', {
      loop: true,
      watchSlidesProgress: true,
      watchSlidesVisibility: true,
      slidesPerView: 3,
      wrapperClass: 'carousel__contentsInner',
      slideClass: 'carousel__panel',
      slideActiveClass: utility.className.current,
      slideVisibleClass: utility.className.show,

      pagination: {
        el: '.carousel__pagination',
        clickable: true,
        bulletActiveClass: utility.className.current,
        bulletClass: 'carousel__paginationItem',
      },

      a11y: {
        notificationClass: 'carousel__notification',
      },
    });
  }
}
